import { GetStaticPropsResult } from 'next';
import { SSRConfig } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { GeneralPage, get404PageStaticProps, GetGeneralPageStaticPropsContext, GetGeneralPageStaticPropsResponse, Website } from 'ui';

export async function getStaticProps(context: GetGeneralPageStaticPropsContext): Promise<GetStaticPropsResult<SSRConfig & GetGeneralPageStaticPropsResponse>> {
  const res = await get404PageStaticProps({ website: Website.Shopper })(context as GetGeneralPageStaticPropsContext);

  return { props: { ...res.props, ...(await serverSideTranslations(res.props.locale ?? 'en', ['common'])) } };
}

export default GeneralPage;
